<script setup lang="ts">
import { LeftArrowIcon, RightArrowIcon } from '@glow/shared/components/icons';
import MasterPreview from './MasterPreview.vue';
import { Mousewheel, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import HomeBlockHeader from './HomeBlockHeader.vue';
import { breakpoints } from '@glow/shared/lib/breakpoints';
import { graphql } from '@/gql';
import { useQuery } from '@urql/vue';
import { useAddressStore } from '@/stores/address';
import { useRoutes } from '@glow/shared/lib/routes';

const { getUrl } = useRoutes();
const addressStore = useAddressStore();
const { data: queryData } = useQuery({
  query: graphql(/* GraphQL */ `
    query NearbyMastersBlock_MastersQuery($address: String) {
      masters(address: $address) {
        edges {
          id
          ...MasterPreview_MasterFragment
        }
      }
    }
  `),
  variables: toRef(() => ({ address: addressStore.address })),
});
const masters = toRef(() => queryData.value?.masters.edges);
const moreLink = getUrl('partnerCatalog', {
  city: 'moscow',
  type: 'master',
  address: addressStore.address,
  radius: 100,
});
</script>

<template>
  <section v-if="masters?.length" class="nearby-masters section">
    <HomeBlockHeader title="Мастера рядом" :more-link="moreLink" />

    <div class="nearby-masters__info">
      <div class="nearby-masters__address-box">
        <template v-if="addressStore.address">
          <span class="nearby-masters__address-label">Рядом с адресом:</span>
          <address class="nearby-masters__address d-inline text-primary">
            {{ addressStore.address }}
          </address>
        </template>
        <template v-else>
          Укажите адрес, чтобы увеличить точность поиска мастеров поблизовсти
        </template>
      </div>
    </div>

    <div class="nearby-master-slider">
      <Swiper
        :modules="[Navigation, Mousewheel]"
        :navigation="{
          prevEl: '.nearby-master-slider__previous-button',
          nextEl: '.nearby-master-slider__next-button',
        }"
        :mousewheel="{ forceToAxis: true }"
        :space-between="16"
        auto-height
        slides-per-view="auto"
        :breakpoints="{
          [breakpoints.xs]: {
            spaceBetween: 8,
          },
          [breakpoints.md]: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          [breakpoints.xxl]: {
            slidesPerView: 3,
          },
        }"
      >
        <SwiperSlide v-for="master in masters" :key="master.id" class="h-100">
          <MasterPreview class="page-card p-4 h-100" :master="master" />
        </SwiperSlide>
      </Swiper>

      <AppButton
        class="nearby-master-slider__previous-button"
        color="white"
        border-radius="full"
        :icon-start="LeftArrowIcon"
        icon-start-color="dark"
      />
      <AppButton
        class="nearby-master-slider__next-button"
        color="white"
        border-radius="full"
        :icon-start="RightArrowIcon"
        icon-start-color="dark"
      />
    </div>
  </section>
</template>

<style lang="scss" scoped>
.nearby-masters {
  position: relative;

  &__info {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__address-box {
    display: flex;

    @media (max-width: 640px) {
      display: block;
    }

    @media (max-width: 480px) {
      display: none;
    }
  }

  &__address {
    font-style: normal;
  }

  &__address-label {
    margin-right: 5px;
  }
}

.nearby-master-slider {
  --slider-button-offset: calc(-1 * var(--app-spacing-6));

  &__previous-button,
  &__next-button {
    position: absolute;
    top: 50%;
    padding: 10px;
    background: var(--app-color-white);
    box-shadow: 0px 0px 16px 0px rgba(20, 20, 20, 0.16);
    z-index: 2;

    svg {
      width: 28px;
      height: 28px;
    }
  }

  &__previous-button {
    left: var(--slider-button-offset);
  }

  &__next-button {
    right: var(--slider-button-offset);
  }

  .swiper-button-disabled {
    display: none;
  }
}
</style>
