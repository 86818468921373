<script setup lang="ts">
import { CloseIcon } from '@glow/shared/components/icons';
import { useRoutes } from '@glow/shared/lib/routes';

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const { getUrl } = useRoutes();
const { staticUrl } = useRuntimeConfig().public;
const backgroundUrl = toRef(
  () => `url(${staticUrl}/images/home/become-master.png)`
);
const backgroundMobileUrl = toRef(
  () => `url(${staticUrl}/images/home/become-master-mobile.png)`
);
</script>

<template>
  <section class="become-master section">
    <div class="become-master__content">
      <h2 class="become-master__title">
        Хочешь размещать свои услуги на Glow?
      </h2>
      <p class="become-master__description">
        Зарегистрируй профиль мастера и получай записи уже сейчас
      </p>
    </div>
    <AppButton
      class="become-master__button"
      color="white"
      :to="getUrl('masterLanding')"
    >
      Стать мастером
    </AppButton>

    <AppButton
      class="become-master__close-button shadow"
      size="xs"
      color="light"
      border-radius="full"
      :icon-start="CloseIcon"
      icon-start-color="dark"
      @click="emit('close')"
    />
  </section>
</template>

<style lang="scss" scoped>
.become-master {
  border-radius: 24px;
  padding: 52px 32px;
  position: relative;
  overflow: hidden;
  background:
    v-bind(backgroundUrl) 100% 0 / contain no-repeat,
    linear-gradient(91deg, #a493d8 0%, #5f72e9 100%);

  @include media-breakpoint-down(md) {
    padding: 24px 16px;
    right: 0;
    width: 100%;
    height: 75%;
    background:
      v-bind(backgroundMobileUrl) 0 100% / contain no-repeat,
      linear-gradient(91deg, #a493d8 0%, #5f72e9 100%);
  }

  &__content {
    max-width: 415px;
    position: relative;
    z-index: 1;

    @include media-breakpoint-down(lg) {
      max-width: 350px;
    }

    @include media-breakpoint-down(md) {
      max-width: 250px;
    }
  }

  &__title {
    color: #fff;
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 8px;

    @include media-breakpoint-down(lg) {
      font-size: 28px;
      line-height: 32px;
    }

    @include media-breakpoint-down(md) {
      font-size: 24px;
      line-height: 27px;
    }
  }

  &__description {
    color: #fff;
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 24px;

    @include media-breakpoint-down(md) {
      max-width: 160px;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  &__button {
    @include media-breakpoint-down(md) {
      margin-top: 120px;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      position: relative;
      z-index: 1;
    }
  }

  &__close-button {
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 1;
  }
}
</style>
