<script setup lang="ts">
import { LeftArrowIcon, RightArrowIcon } from '@glow/shared/components/icons';
import RecentBlogPostsBlockItem from './RecentBlogPostsBlockItem.vue';
import { Mousewheel, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import HomeBlockHeader from './HomeBlockHeader.vue';
import { breakpoints } from '@glow/shared/lib/breakpoints';
import { useQuery } from '@urql/vue';
import { graphql } from '@/gql';
import { useRoutes } from '@glow/shared/lib/routes';

const { getUrl } = useRoutes();
const { data: queryData } = useQuery({
  query: graphql(/* GraphQL */ `
    query RecentBlogPostsBlock_BlogPostsQuery {
      blogPosts {
        id
        ...RecentBlogPostsBlock_BlogPostFragment
      }
    }
  `),
});
const blogPosts = toRef(() => queryData.value?.blogPosts);
</script>

<template>
  <section v-if="blogPosts?.length" class="posts section">
    <HomeBlockHeader title="Мы пишем" :more-link="getUrl('blogPosts')" />

    <div class="slider">
      <Swiper
        :modules="[Navigation, Mousewheel]"
        :navigation="{
          prevEl: '.slider__previous-button',
          nextEl: '.slider__next-button',
        }"
        :mousewheel="{ forceToAxis: true }"
        :space-between="20"
        slides-per-view="auto"
        :breakpoints="{
          [breakpoints.xs]: {
            slidesPerView: 1.25,
          },
          [breakpoints.md]: {
            slidesPerView: 2,
          },
        }"
      >
        <SwiperSlide
          v-for="blogPost in blogPosts"
          :key="blogPost.id"
          class="h-auto"
        >
          <RecentBlogPostsBlockItem
            class="page-card p-4 h-100"
            :blog-post="blogPost"
          />
        </SwiperSlide>
      </Swiper>

      <AppButton
        class="slider__previous-button"
        color="white"
        border-radius="full"
        :icon-start="LeftArrowIcon"
        icon-start-color="dark"
      />
      <AppButton
        class="slider__next-button"
        color="white"
        border-radius="full"
        :icon-start="RightArrowIcon"
        icon-start-color="dark"
      />
    </div>
  </section>
</template>

<style lang="scss" scoped>
.title {
  font-weight: 700;
  font-size: stretch-size(375, 1920, 24, 32);
}

.posts {
  position: relative;
}

.slider {
  --slider-button-offset: calc(-1 * var(--app-spacing-6));

  &__previous-button,
  &__next-button {
    position: absolute;
    top: 50%;
    padding: 10px;
    background: var(--app-color-white);
    box-shadow: 0px 0px 16px 0px rgba(20, 20, 20, 0.16);
    z-index: 2;

    svg {
      width: 28px;
      height: 28px;
    }
  }

  &__previous-button {
    left: var(--slider-button-offset);
  }

  &__next-button {
    right: var(--slider-button-offset);
  }

  .swiper-button-disabled {
    display: none;
  }
}
</style>
