<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router';

const props = defineProps<{
  title: string;
  moreLink?: RouteLocationRaw;
}>();
</script>

<template>
  <div class="head">
    <h2 class="m-0">{{ props.title }}</h2>
    <NuxtLink :to="props.moreLink" class="watch-all-link">
      смотреть все
    </NuxtLink>
  </div>
</template>

<style scoped lang="scss">
.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 8px;
}

.watch-all-link {
  color: var(--app-color-black-tertiary);
  font-weight: 500;
  font-size: 20px;
  white-space: nowrap;

  @include media-breakpoint-up(md) {
    font-size: 24px;
  }
}
</style>
