<script setup lang="ts">
import { formatDate } from '@glow/shared/utils/format';
import { type FragmentType, graphql } from '@/gql';
import {
  PartnerLocationSelectItem,
  type PartnerLocationSelectItemLocation,
} from '@/features/partners';
import { useRoutes } from '@glow/shared/lib/routes';

const appointmentFragment = graphql(/* GraphQL */ `
  fragment AppointmentsBlockItem_AppointmentFragment on Appointment {
    id
    startedAt
    endedAt
    workPlace {
      id
      address
      location
      master {
        id
        slug
        fullName
        avatarUrl
      }
      metroStations {
        id
        color
        name
        location
      }
      company {
        id
        address
        name
      }
      city {
        id
        slug
      }
    }
    services {
      id
      service {
        id
        name
      }
    }
  }
`);
const props = defineProps<{
  appointment: FragmentType<typeof appointmentFragment>;
}>();

const { getUrl } = useRoutes();
const appointment = useFragmentData(
  appointmentFragment,
  () => props.appointment
);
const subject = computed(() =>
  appointment.value.services.map((service) => service.service.name).join(', ')
);
const masterLink = computed(() =>
  getUrl('partnerDetails', {
    type: 'master',
    city: appointment.value.workPlace.city.slug,
    partner: appointment.value.workPlace.master.slug,
  })
);
const location = computed<PartnerLocationSelectItemLocation>(() => {
  const { workPlace } = appointment.value;
  return {
    ...workPlace,
    type: workPlace.company ? 'company' : 'personal',
    name: workPlace.company?.name ?? workPlace.address,
  };
});
</script>

<template>
  <div class="page-card card">
    <div class="card__head">
      <div>
        <span class="card__date">
          Запись на {{ formatDate(appointment.startedAt) }}
        </span>
        <span class="card__subject">{{ subject }}</span>
      </div>
    </div>
    <AppSeparator />
    <NuxtLink :to="masterLink" class="d-flex gap-2 text-black">
      <AppAvatar
        class="card__avatar"
        :name="appointment.workPlace.master.fullName"
        :src="appointment.workPlace.master.avatarUrl"
      />
      <div class="card__fullname">
        {{ appointment.workPlace.master.fullName }}
      </div>
    </NuxtLink>
    <PartnerLocationSelectItem :location="location" :max-stations="1" />
  </div>
</template>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  gap: var(--app-spacing-3);
  border-radius: var(--app-br-md);
  padding: var(--app-spacing-4);
  font-family: 'Nunito Sans';
  font-weight: 400;

  &__head {
    flex: 1;
    display: flex;
    justify-content: space-between;
  }

  &__date {
    display: block;
  }

  &__avatar {
    height: 32px;
    width: 32px;
    border-radius: 50%;
  }

  &__date {
    font-weight: 700;
    line-height: 22px;
  }

  &__subject {
    line-height: 24px;
  }

  &__fullname {
    font-family: 'Nunito Sans';
    font-weight: 700;
    margin: 8px 0;
  }

  &__location {
    font-size: 14px;
    line-height: 20px;
  }

  &__location-icon {
    color: var(--app-color-primary);
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  &__location-address {
    font-style: normal;
    color: var(--app-color-black-tertiary);
  }
}
</style>
