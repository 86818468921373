<script setup lang="ts">
import { LeftArrowIcon, RightArrowIcon } from '@glow/shared/components/icons';
import AppointmentsBlockItem from './AppointmentsBlockItem.vue';
import { Mousewheel, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { breakpoints } from '@glow/shared/lib/breakpoints';
import { useQuery } from '@urql/vue';
import { graphql } from '@/gql';

type AppointmentListTab = 'past' | 'upcoming';
const selectedTab = ref<AppointmentListTab>('upcoming');

function getTabClasses(tab: AppointmentListTab) {
  return [
    'cursor-pointer',
    'transition-md',
    selectedTab.value === tab ? 'text-black-primary' : 'text-black-tertiary',
  ];
}

const isLoggedIn = useIsLoggedIn();
const { data: queryData } = useQuery({
  query: graphql(/* GraphQL */ `
    query AppointmentsBlock_AppointmentsQuery(
      $startDate: DateTime!
      $endDate: DateTime!
    ) {
      appointments(startDate: $startDate, endDate: $endDate) {
        id
        ...AppointmentsBlockItem_AppointmentFragment
      }
    }
  `),
  variables: {
    startDate: 0,
    endDate: 10000000000000,
  },
  pause: toRef(() => !isLoggedIn.value),
});
const appointments = toRef(() => queryData.value?.appointments);
</script>

<template>
  <section v-if="appointments?.length" class="appointments section">
    <div class="head">
      <div class="head__items">
        <h2
          :class="getTabClasses('upcoming')"
          @click="selectedTab = 'upcoming'"
        >
          Предстоящие записи
        </h2>
        <h2 :class="getTabClasses('past')" @click="selectedTab = 'past'">
          Прошлые записи
        </h2>
      </div>
    </div>

    <div class="appointment-slider">
      <Swiper
        :modules="[Navigation, Mousewheel]"
        :navigation="{
          prevEl: '.appointment-slider__previous-button',
          nextEl: '.appointment-slider__next-button',
        }"
        :mousewheel="{ forceToAxis: true }"
        slides-per-view="auto"
        auto-height
        :breakpoints="{
          [breakpoints.xs]: {
            slidesPerView: 1.1,
            spaceBetween: 8,
          },
          [breakpoints.md]: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
        }"
      >
        <SwiperSlide
          v-for="appointment in appointments"
          :key="appointment.id"
          class="h-auto"
        >
          <AppointmentsBlockItem class="h-100" :appointment="appointment" />
        </SwiperSlide>
      </Swiper>

      <AppButton
        class="appointment-slider__previous-button"
        color="white"
        border-radius="full"
        :icon-start="LeftArrowIcon"
        icon-start-color="dark"
      />
      <AppButton
        class="appointment-slider__next-button"
        color="white"
        border-radius="full"
        :icon-start="RightArrowIcon"
        icon-start-color="dark"
      />
    </div>
  </section>
</template>

<style lang="scss" scoped>
.appointments {
  position: relative;
}

.appointment-slider {
  --slider-button-offset: calc(-1 * var(--app-spacing-6));

  &__previous-button,
  &__next-button {
    position: absolute;
    top: 50%;
    padding: 10px;
    background: var(--app-color-white);
    box-shadow: 0px 0px 16px 0px rgba(20, 20, 20, 0.16);
    z-index: 2;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    svg {
      width: 28px;
      height: 28px;
    }
  }

  &__previous-button {
    left: var(--slider-button-offset);
  }

  &__next-button {
    right: var(--slider-button-offset);
  }

  .swiper-button-disabled {
    display: none;
  }
}

.head {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 1300px) {
    margin-bottom: 15px;
  }

  &__items {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 24px;
    white-space: nowrap;
  }
}
</style>
